import React, { useEffect, useState } from "react";
import Odometer from "react-odometerjs";
import { IMAGES } from "Shared/Images";
import "./style.scss";

const Jackpot = () => {
  const [value, setValue] = useState(getRndInteger(912345678, 999999999));

  function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    const timeoutId = setInterval(() => {
      setValue((prev) => prev + getRndInteger(12345, 98765));
    }, 3000);
    return () => {
      clearInterval(timeoutId);
    };
  }, []);

  return (
    <div className="container">
      <div className="d-flex align-items-center flex-column flex-xl-row justify-content-center jackport_sec mb-4 mb-lg-5">
        <div className="col text-center">
          <figure className="d-inline-block mb-lg-0"><img src={IMAGES.JackpotPic} alt="BT2 Jackport" width={200} className="img-fluid" /></figure>
        </div>
        <div className="col">
          <Odometer value={value} format="ddddddddd" />
        </div>
      </div>
    </div>
  );
};

export default Jackpot;
