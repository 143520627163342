import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import _debounce from "lodash/debounce";
import { useTranslation } from "react-i18next";

import { Button, Headings } from "Components";
import DropDown from "Components/Atoms/Dropdown";
import { getCategories, getGames, getProviders } from "Redux/Actions/common";
import { fetchGameLink } from "Redux/Actions/game";
import { ROUTES } from "Routes/constants";
import { openLoginModal } from "Redux/Actions/user";
import "./style.scss";
import { GAME_PLAY_MODES } from "Shared/Constants";


const Categories = ({ }) => {

  const { t } = useTranslation();

  const token = useSelector((state) => state.auth.token);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [listData, setListData] = useState([]);
  const [activeCategory, setActiveCategory] = useState({
    active: 1,
    id: "",
  });
  const [searchValue, setSearchValue] = useState("");
  const [provider, setProvider] = useState({ label: "All", value: "" });
  const [categoryHeadings, setCategoryHeadings] = useState([]);
  const [ddOptions, setDdOptions] = useState([]);
  const [limit, setLimit] = useState(12);
  const [pageNo, setPageNo] = useState(0);
  const [searchData, setSearchData] = useState([]);
  const [searchPageNo, setSearchPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const handleHeadingClick = (value) => {
    setActiveCategory(value);
  };

  const handleDropdownChange = (value) => {
    setProvider(value);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);

    if (event.target.value.trim().length) {
      searchDebounceFn(event.target.value.trim(), 0, activeCategory, provider, limit, true);
    } else {
      setSearchData([]);
      setSearchPageNo(0);
      fetchGames("", 0, activeCategory, provider, true);
    }
  };

  const fetchGames = (value, pageNo = 0, activeCategory, provider, isClearPage = false) => {
    let payload = {
      categoryId: activeCategory.id,
      skip: pageNo,
      limit: limit,
      searchKey: "",
      providerName: provider?.label == "All" ? "" : provider?.label || ""
    };
    if (activeCategory?.id == "popular") {
      delete payload.categoryId;
      payload.isPopular = true;
    }
    dispatch(
      getGames(payload, (response) => {
        setTotalCount(response?.totalCount || 0);
        if (isClearPage) {
          setListData(response?.games || []);
          if (response?.totalCount == 0 || response?.totalCount <= limit) {
            setPageNo(0);
          } else {
            setPageNo(1);
          }
        } else if (response?.totalCount == 0 || response?.totalCount <= limit) {
          setListData(response?.games);
          setPageNo(0);
        } else {
          setListData((prev) => [...prev, ...response?.games]);
          setPageNo((prev) => prev + 1);
        }
      })
    );
  };

  const debounceFn = useCallback(_debounce(fetchGames, 500), []);

  const fetchSearchGames = (value, pageNo = 0, activeCategory, provider, limit, isClearPrevState = false) => {
    let payload = {
      categoryId: activeCategory.id,
      skip: pageNo,
      limit: limit,
      searchKey: value ? value : searchValue,
      providerName: provider?.label == "All" ? "" : provider?.label || ""
    };
    if (activeCategory?.id == "popular") {
      delete payload.categoryId;
      payload.isPopular = true;
    }
    dispatch(
      getGames(payload, (response) => {
        setTotalCount(response?.totalCount || 0);
        if (isClearPrevState) {
          setSearchData(response?.games);
          if (response?.totalCount == 0 || response?.totalCount <= limit) {
            setSearchPageNo(0);
          } else {
            setSearchPageNo(1);
          }
        } else if (response?.totalCount == 0 || response?.totalCount <= limit) {
          setSearchData(response?.games);
          setSearchPageNo(0);
        } else {
          setSearchData((prev) => [...prev, ...response?.games]);
          setSearchPageNo((prev) => prev + 1);
        }
      })
    );
  };
  const searchDebounceFn = useCallback(_debounce(fetchSearchGames, 500), []);

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(
      getCategories((response) => {
        let options = response?.gameCategory || [];
        options.unshift({ categoryName: "Popular", _id: "popular" });
        options.unshift({ categoryName: "All", _id: "" });
        setCategoryHeadings(options);
        setActiveCategory({ active: 1, id: "" });
      })
    );

    dispatch(
      getProviders((response) => {
        let ddOptions = [];
        ddOptions = response?.map((item) => ({ label: item?.name, value: item?._id })) || [];
        setDdOptions(ddOptions);
        ddOptions.unshift({ label: "All", value: "" });
      })
    );
  }, []);

  useEffect(() => {
    if (searchValue?.length) {
      let payload = {
        categoryId: activeCategory?.id || "",
        skip: 0,
        limit: limit,
        searchKey: searchValue,
        providerName: provider?.label == "All" ? "" : provider?.label || ""
      };
      if (activeCategory?.id == "popular") {
        delete payload.categoryId;
        payload.isPopular = true;
      }
      dispatch(
        getGames(payload, (response) => {
          setSearchData(response?.games || []);
          setTotalCount(response?.totalCount || 0);
          if (response?.totalCount == 0 || response?.totalCount <= limit) {
            setSearchPageNo(0);
          } else {
            setSearchPageNo(1);
          }
        })
      );
    }
    else {
      let payload = {
        categoryId: activeCategory?.id || "",
        skip: 0,
        limit: limit,
        searchKey: "",
        providerName: provider?.label == "All" ? "" : provider?.label || ""
      };
      if (activeCategory?.id == "popular") {
        delete payload.categoryId;
        payload.isPopular = true;
      }
      dispatch(
        getGames(payload, (response) => {
          setListData(response?.games || []);
          setTotalCount(response?.totalCount || 0);
          if (response?.totalCount == 0 || response?.totalCount <= limit) {
            setPageNo(0);
          } else {
            setPageNo(1);
          }
        })
      );
    }
  }, [provider, activeCategory]);

  const playGame = (item) => {
    if (!token) {
      dispatch(openLoginModal(true));
    } else {
      dispatch(fetchGameLink({ id: item._id,playMode : GAME_PLAY_MODES.REAL }, (response) => {
        setTimeout(() => {
          const win = window.open(response, "_blank");
          if (win != null) {
            win.focus();
          }
        }, 0)
      }))
    }
  };

  const handleDemoClick = (item) => {
      dispatch(fetchGameLink({ id: item._id,playMode : GAME_PLAY_MODES.DEMO }, (response) => {
        setTimeout(() => {
          const win = window.open(response, "_blank");
          if (win != null) {
            win.focus();
          }
        }, 0)
      }));
  };

  const seeAll = () => {
    history.push(ROUTES.GAMES);
  };

  return (
    <div className="categories_section">
      <div className="container" >
        {location.pathname == ROUTES.HOME ? <div className="common_title">
          <h2 className="h2">{t("categories")}</h2>
        </div> : ""}

        <ul className="list-unstyled d-flex flex-nowrap categories_list my-md-4 my-3">
          <Headings data={categoryHeadings} active={activeCategory} handleClick={handleHeadingClick} />
        </ul>

        <div className="row search_secn_cats">
          <div className="col-sm-7 mt-3">
            <div className="search_box">
              <input type="text" className="form-control" placeholder={t("search")} value={searchValue} onChange={handleSearchChange} />
            </div>
          </div>
          <div className="col-sm-5 mt-3">
            <div className="dropdown_List ms-auto">
              <DropDown options={ddOptions} value={provider} onChange={handleDropdownChange} isFormik={false} />
            </div>
          </div>
        </div>

        <div className="cate_list_block py-4">
          {searchValue.length ?

            <InfiniteScroll
              dataLength={searchData.length || 0}
              next={() => fetchSearchGames(searchValue, searchPageNo, activeCategory, provider, limit)}
              loader={
                <div className="loader_text">
                  <span data-loading-text="Loading..."></span>
                </div>
              }
              hasMore={location.pathname == ROUTES.HOME ? false : totalCount > searchData.length}
              style={{ overflow: "hidden" }}
              scrollThreshold={0.7}
            >
              <div className="row">
                {searchData?.length ? searchData?.map((item, index) => (
                  <div key={index} className="col-md-3 col-sm-4 col-6 mb-4">
                    <div className="game_box">
                      <figure>
                        <img width="400px" src={item?.gameImage} alt="game_image" className="img-fluid" />
                      </figure>
                      {item?.isMaintainence ? (
                        <div className="game_card_text text-center">
                          <div className="game_summary d-flex justify-content-center align-items-center flex-column text-center text-white">
                            <h6 className="h5">{item?.gameName}</h6>
                            <span className="h5 text-white">{t("game-maintenance")}</span>
                            <p>{t("game-maintenance2")}</p>
                          </div>
                        </div>
                      ) : (
                        <div className="game_card_text text-center">
                          <div className="game_summary d-flex justify-content-center align-items-center flex-column text-center">
                            <h6 className="h5">{item?.gameName}</h6>
                            <a className="btn btn-md btn-red d-flex align-items-center justify-content-center" onClick={() => playGame(item)}>
                              {t("play")}
                            </a>
                            {item?.isDemoAvailable ?
                              <a className="btn btn-md btn-green d-flex align-items-center mt-1 justify-content-center" onClick={() => handleDemoClick(item)}>
                                {t("demo")}
                              </a>
                             : ""
                             }
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )) :
                  <div className="d-flex align-items-center justify-content-center h6 mb-0 text-center no-data">
                    {t("no-data")}
                  </div>
                }
              </div>
            </InfiniteScroll>
            :
            <InfiniteScroll
              dataLength={listData.length || 0}
              next={() => fetchGames(searchValue, pageNo, activeCategory, provider)}
              loader={
                <div className="loader_text">
                  <span data-loading-text="Loading..."></span>
                </div>
              }
              hasMore={location.pathname == ROUTES.HOME ? false : totalCount > listData.length}
              style={{ overflow: "hidden" }}
              scrollThreshold={0.7}
            >
              <div className="row">
                {listData?.length ? listData?.map((item, index) => (
                  <div key={index} className="col-md-3 col-sm-4 col-6 mb-4">
                    <div className="game_box">
                      <figure>
                        <img width="400px" src={item?.gameImage} alt="game_image" className="img-fluid" />
                      </figure>
                      {item?.isMaintainence ? (
                        <div className="game_card_text text-center">
                          <div className="game_summary d-flex justify-content-center align-items-center flex-column text-center text-white">
                            <h6 className="h5">{item?.gameName}</h6>
                            <span className="h5 text-white">{t("game-maintenance")}</span>
                            <p>{t("game-maintenance2")}</p>
                          </div>
                        </div>
                      ) : (
                        <div className="game_card_text text-center">
                          <div className="game_summary d-flex justify-content-center align-items-center flex-column text-center">
                            <h6 className="h5">{item?.gameName}</h6>
                            <a className="btn btn-md btn-red d-flex align-items-center justify-content-center" onClick={() => playGame(item)}>
                              {t("play")}
                            </a>
                            {item?.isDemoAvailable ?
                              <a className="btn btn-md btn-green d-flex align-items-center mt-1 justify-content-center" onClick={() => handleDemoClick(item)}>
                                {t("demo")}
                              </a>
                             : ""
                             }
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )) :
                  <div className="d-flex align-items-center justify-content-center h6 mb-0 text-center no-data">
                    {t("no-data")}
                  </div>
                }
              </div>
            </InfiniteScroll>
          }

        </div>
        {location.pathname == ROUTES.HOME ? (totalCount > 6 ?
          <div className='text-center common-btn mt-4 mt-md-5'>
            <Button className="btn btn-lg btn-red" onClick={seeAll}>{t("see-all")}</Button>
          </div> : "") : ""
        }

      </div>
    </div>
  );
};

export default Categories;