import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "Routes/constants";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { useDispatch } from 'react-redux';
import { fetchFooterPagesCmsContent } from "Redux/Actions/CMS";
import { CMS_SECTION_TYPE } from "Shared/Constants";

const ThankYou = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [count, setCount] = useState(5);
  const intervalId = useRef(null);
  const [content,setContent] = useState("");

  const clear = (id) => {
    clearInterval(id);
  };

  useEffect(() => {   
    dispatch(fetchFooterPagesCmsContent({ type : CMS_SECTION_TYPE.THANK_YOU },(response)=>{
       setContent(response?.data || "");
    }))

    intervalId.current = setInterval(() => {
      setCount((prev) => --prev);
    }, 1000);

    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  useEffect(() => {
    if (count == 0) {
      clear(intervalId.current);
      history.push(ROUTES.HOME);
    }
  }, [count]);

  return (
    <div className="container py-md-5 py-3">
      <div className="common_screen">
        <div dangerouslySetInnerHTML={{__html : content?.description || ""}}></div>
      </div>
    </div>
  );
};

export default ThankYou;
