//libraries
import ReactDatePicker from "react-datepicker";
import ReactPaginate from "react-paginate";
import moment from "moment";

//hooks
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Table } from "Components";

//utils
import {indexHistory, BONUS_TYPES_CODES, BONUS_TYPES_OPTIONS } from "Shared/Constants";
import { IMAGES } from "Shared/Images";
import { getBonusTransactions } from "Redux/Actions/user";

//styles
import "react-datepicker/dist/react-datepicker.css";

const BonusTransactions = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [type, setType] = useState("");

  const headings = [t("no"), t("date"), t("username"), t("amount"),t("bonus-type")];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalCount, setTotalCount] = useState([]);

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchBonusTransactions();
}, []);

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    setType("");
    let payload = {
      limit: 10,
      skip: 0,
      fromDate: "",
      toDate: "",
      bonusType: "",
    };
    setCurrentIndex(0);
    dispatch(
      getBonusTransactions(payload, (response) => {
        setData(response?.data?.transation);
        setTotalCount(response?.data?.totalCount);
      })
    );
  };

  const handleStartDateChange = (Date) => {
    setStartDate(Date);
    setEndDate("");
  };

  const handleEndDateChange = (Date) => {
    setEndDate(Date);
  };

  const handleTypeChange = (selected) => {
    setType(selected);
  };

  const handleApply = () => {
    let payload = {
      limit: 10,
      skip: 0,
      fromDate: startDate,
      toDate: endDate,
      bonusType: type.value,
    };
    setCurrentIndex(0);
    dispatch(
        getBonusTransactions(payload, (response) => {
        setData(response?.data?.transation);
        setTotalCount(response?.data?.totalCount);
      })
    );
  };

  const handlePageChange = ({ selected }) => {
    let payload = {
      limit: 10,
      skip: selected,
      fromDate: startDate,
      toDate: endDate,
      bonusType: type.value,
    };
    setCurrentIndex(selected);
    dispatch(
        getBonusTransactions(payload, (response) => {
        setData(response?.data?.transation);
        setTotalCount(response?.data?.totalCount);
      })
    );
  };

  const fetchBonusTransactions = ()=>{
    let payload = {
        limit: 10,
        skip: 0,
        fromDate: startDate,
        toDate: endDate,
        bonusType: type?.value || "",
      };
      dispatch(
          getBonusTransactions(payload, (response) => {
          setData(response?.data?.transation);
          setTotalCount(response?.data?.totalCount);
          setCurrentIndex(0);
        })
      );
  }

  const showBonusType = (value) => {
    switch (value) {
      case BONUS_TYPES_CODES.REFERRAL:
        return t("referral");
      case BONUS_TYPES_CODES.ROLLINGAN:
        return t("rollingan");
      case BONUS_TYPES_CODES.CASHBACK:
        return t("cashback");
      case BONUS_TYPES_CODES.DEPOSIT:
        return t("deposit");
      case BONUS_TYPES_CODES.FIRST_TIME_DEPOSIT:
        return t("first-time-deposit");
      case BONUS_TYPES_CODES.RECURRING_DEPOSIT:
        return t("recurring-deposit");
      default : 
       return ""
    }
  };

  return (
    <div className="w-100 form_front pt-4">
      <div className="W-100 form_card bg-white text-start text-dark p-4">
        <div className="row align-items-end">
        <div className="col-md-6 col-lg-3">
            <div className="form-group">
              <label className="mb-2">{t("start-date")}</label>
              <div className="position-relative">
                <ReactDatePicker
                  selected={startDate}
                  dateFormat={"dd/MM/yyyy"}
                  onChange={handleStartDateChange}
                  placeholderText={"dd/mm/yyyy"}
                  className="form-control"
                // showFullMonthYearPicker={true}
                />
                <em className="form_icon"><img src={IMAGES.calendar} height="13" width="13" alt="" /></em>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="form-group">
              <label className="mb-2">{t("end-date")}</label>
              <div className="position-relative">
                <ReactDatePicker
                  selected={endDate}
                  dateFormat={"dd/MM/yyyy"}
                  onChange={handleEndDateChange}
                  placeholderText={"dd/mm/yyyy"}
                  minDate={startDate}
                  maxDate={new Date()}
                  className="form-control"
                // showFullMonthYearPicker={true}
                />
                <em className="form_icon"><img src={IMAGES.calendar} height="13px" width="13px" alt="" /></em>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="form-group">
              <label className="mb-2">{t("bonus-type")}</label>
              <Dropdown
                className={"me-2"}
                id="dropdown"
                value={type}
                name="Account"
                placeholder="Choose type"
                options={BONUS_TYPES_OPTIONS}
                onChange={handleTypeChange}
                chooseLabel={t("choose-bonus")}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="form-group d-flex">
              <Button className="btn btn-md btn-primary" type="submit" onClick={handleApply}>
                {t("apply")}
              </Button>
              <button className="btn btn-md btn-transparent ms-2" onClick={handleReset}> {t("reset")}</button>
            </div>
          </div>
        </div>

        <>
          {data?.length ? (
            <>
            <div className="table-responsive">
              <Table className="table text-dark" headings={headings}>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td>{indexHistory(currentIndex, 10) + index}</td>
                      <td>{moment(item.createdAt).format("DD MMM YYYY HH:MM A")}</td>
                      <td>{item?.userName}</td>
                      <td>{item?.bonus ? item?.bonus?.toString()?.split(/(?=(?:\d{3})+(?:\.|$))/g)?.join(",") : 0}</td>
                      <td>{ showBonusType(item?.bonusType)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

              <ReactPaginate
                previousLabel="<<"
                nextLabel=">>"
                breakLabel="..."
                pageCount={Math.ceil(totalCount / 10)}
                marginPagesDisplayed={3}
                onPageChange={handlePageChange}
                containerClassName="pagination justify-content-center mt-4"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLinkClassName="page-link"
                breakClassName="page-item"
                activeClassName="active"
              />
            </>
          ) : (
            <div className="p-5 d-flex flex-column justify-content-center text-center no-result">
              <em><img src={IMAGES.empty} alt="" width="44" /></em>
              <span className="h5 mt-3">{t("no-trans")}</span>
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default BonusTransactions;
