//base
import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";

//Hooks
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

//Components
import { Button, InputFieldFormik } from "Components";
import DropDown from "Components/Atoms/Dropdown";
import Popup from "Components/Atoms/Popup";

//Utils
import { getBankAccounts, getUserDetails, performTransaction } from "Redux/Actions/user";
import { DEPOSIT_BONUS_OPTIONS, DEPOSIT_BONUS_TYPE_CODES } from "Shared/Constants";
import { STATUS_CODES } from "Services/Api/Constants";

//Styles
import "react-phone-input-2/lib/style.css";
import "./style.scss";

const DepositForm = ({}) => {
  const { t } = useTranslation();
  const validate = Yup.object({
    username: Yup.string()
      // .trim()
      .max(60, t("max-60"))
      .required(t("required")),
    amount: Yup.string().required(t("required")),
    note: Yup.string(),
  });

  const userDetails = useSelector((state) => state.user.details);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [accounts, setAccounts] = useState([]);
  const [adminAccounts, setAdminAccounts] = useState([]);

  const [userBankOptions, setUserBankOptions] = useState([]);
  const [userAccount, setUserAccount] = useState([]);
  const [destinationBankOptions, setDestinationBankOptions] = useState([]);

  const [destinationAccountOptions, setDestinationAccountOptions] = useState([]);
  const [userAccountOptions, setUserAccountOptions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [adminAccountType, setAdminAccountType] = useState("");
  const [userAccountType, setUserAccountType] = useState("");
  const [destination, setDestination] = useState("");
  const [error, setError] = useState({
    userAccount: "",
    userAccountType: "",
    adminAccountType: "",
    destination: "",
    userAccountBrand: "",
    adminAccountBrand: "",
  });

  const [userAccountBrand, setUserAccountBrand] = useState([]);
  const [userAccountBrandOptions, setUserAccountBrandOptions] = useState([]);

  const [adminAccountBrand, setAdminAccountBrand] = useState([]);
  const [adminAccountBrandOptions, setAdminAccountBrandOptions] = useState([]);

  const [isFirstDeposit, setIsFirstDeposit] = useState(false);

  useEffect(() => {
    dispatch(
      getBankAccounts({ saveAll: true, isListing: true }, (response) => {
        if (response.statusCode == STATUS_CODES.SUCCESS) {
          let options = Object.entries(response?.data?.bankAccount)?.map(([key, value], index) => {
            return {
              label: key,
              value: key,
            };
          });
          setUserAccountOptions(options || []);
          setAccounts(response?.data?.bankAccount);
        }
      })
    );

    dispatch(
      getBankAccounts({ isAdminAccount: true, isListing: true }, (response) => {
        if (response.statusCode == STATUS_CODES.SUCCESS) {
          let options = Object.entries(response?.data?.bankAccount)?.map(([key, value], index) => {
            return {
              label: key,
              value: key,
            };
          });
          setDestinationAccountOptions(options || []);
          setAdminAccounts(response?.data?.bankAccount);
          setIsFirstDeposit(response?.data?.isFirstDeposit);
        }
      })
    );
  }, []);

  const handleSubmit = (value, { resetForm }) => {
    if (
      !userAccount.value ||
      !userAccountType.value ||
      !destination.value ||
      !adminAccountType.value ||
      !adminAccountBrand.value ||
      !userAccountBrand.value
    ) {
      setError((prev) => ({
        ...prev,
        userAccountType: userAccountType.value ? "" : t("account-req"),
        userAccountBrand: userAccountBrand.value ? "" : t("brand-val"),
        userAccount: userAccount.value ? "" : t("account-req"),
        adminAccountType: adminAccountType.value ? "" : t("account-req"),
        adminAccountBrand: adminAccountBrand.value ? "" : t("brand-val"),
        destination: destination.value ? "" : t("account-req"),
      }));
      return;
    }
    let payload = { ...value, userBankId: userAccount.value, destinationBankId: destination.value, transactionType: 1 };
    payload.amount = payload.amount.replaceAll(/[,]/g, "");
    if (!payload?.depositBonusType?.value) {
      delete payload.depositBonusType;
    } else {
      payload.depositBonusType = payload.depositBonusType.value;
    }
    delete payload.username;
    dispatch(
      performTransaction(payload, (response) => {
        if (enqueueSnackbar) {
          enqueueSnackbar(t(response.msg), {
            variant: response.statusCode === STATUS_CODES.SUCCESS ? "success" : "error",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
        if (response.statusCode === STATUS_CODES.SUCCESS) {
          dispatch(getUserDetails());

          setAdminAccountType("");
          setAdminAccountBrand("");
          setDestination("");

          setUserAccount("");
          setUserAccountBrand("");
          setUserAccountType("");

          setOpenModal(true);
          resetForm({ values: "" });
          setAdminAccountType("");
          // history.push("/verifyOTP");
        }
      })
    );
  };

  const handleUserAccountBrandChange = (selected, userAccountType) => {
    let options = accounts[userAccountType][selected]?.map((item, index) => ({
      label: `${item.accountName} - ${item.accountNumber}`,
      value: item._id,
      index: index,
    }));
    setUserBankOptions(options || []);
    if (options?.length == 1) {
      setUserAccount(options[0]);
      setError((prev) => ({ ...prev, userAccount: "" }));
    }
  };

  const handleUserAccountTypeChange = (selected, setState, setValue) => {
    let options = Object.entries(accounts[selected])?.map(([key, value], index) => {
      return {
        label: key,
        value: key,
      };
    });
    setState(options || []);
    if (options?.length == 1) {
      setValue(options[0]);
      handleUserAccountBrandChange(options[0].value, selected);
      setError((prev) => ({ ...prev, userAccountBrand: "" }));
    }
  };

  const handleAdminAccountBrandChange = (selected, accountType) => {
    let options = adminAccounts[accountType][selected]?.map((item, index) => ({
      label: `${item.accountName} - ${item.accountNumber}`,
      value: item._id,
      index: index,
    }));
    setDestinationBankOptions(options || []);
    if (options?.length == 1) {
      setDestination(options[0]);
      setError((prev) => ({ ...prev, destination: "" }));
    }
  };

  const handleAdminAccountTypeChange = (selected, setState, setValue, type) => {
    let options = Object.entries(adminAccounts[selected])?.map(([key, value], index) => {
      return {
        label: key,
        value: key,
      };
    });
    setState(options || []);
    if (options?.length == 1) {
      setValue(options[0]);
      handleAdminAccountBrandChange(options[0].value, selected);
      setError((prev) => ({ ...prev, userAccountBrand: "" }));
    }
  };

  const showBonusTypeOptions = () => {
    if (isFirstDeposit) {
      return DEPOSIT_BONUS_OPTIONS;
    } else if (userDetails?.depositBonusType == DEPOSIT_BONUS_TYPE_CODES.FIRST_TIME_DEPOSIT) {
      return [DEPOSIT_BONUS_OPTIONS[0]];
    } else if (userDetails?.depositBonusType == DEPOSIT_BONUS_TYPE_CODES.RECURRING_DEPOSIT) {
      return [DEPOSIT_BONUS_OPTIONS[1]];
    }
    return [];
  };

  const setDefaultBonusTypeValue = () => {
    if (!isFirstDeposit && userDetails?.depositBonusType == DEPOSIT_BONUS_TYPE_CODES.FIRST_TIME_DEPOSIT) {
      return DEPOSIT_BONUS_OPTIONS[0];
    } else if (!isFirstDeposit && userDetails?.depositBonusType == DEPOSIT_BONUS_TYPE_CODES.RECURRING_DEPOSIT) {
      return DEPOSIT_BONUS_OPTIONS[1];
    }
    return "";
  };

  return (
    <div className="w-100 form_front pt-4">
      <div className="W-100 form_card bg-white text-start p-4">
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validate}
          initialValues={{
            username: userDetails.userName,
            amount: "",
            note: "",
            depositBonusType: setDefaultBonusTypeValue(),
          }}
        >
          {(props) => (
            <>
              <Form
                className="form_pad"
                onChange={(e) => {
                  if (e?.target?.name == "amount") {
                    let str1 = e?.target?.value.trim().replaceAll(/[^0-9,]/g, "");
                    str1 = str1?.replaceAll(/[,]/g, "");
                    props.setFieldValue(
                      "amount",
                      str1
                        ?.toString()
                        .split(/(?=(?:\d{3})+(?:\.|$))/g)
                        .join(",")
                    );
                  }
                }}
              >
                <div className="row g-3">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group sky_bg_group">
                          <label className="form-label">{t("username")}</label>
                          <span className="d-block text-black text-start ps-0">{userDetails?.userName || ""}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-6 col-xl-4">
                        <div className="form-group sky_bg_group">
                          <label htmlFor="inputEmail4" className="form-label">
                            {t("account")} <sup className="req_txt">*</sup>
                          </label>
                          <DropDown
                            className={"me-2"}
                            id="dropdown"
                            value={userAccountType}
                            options={userAccountOptions}
                            placeholder={t("choose-account")}
                            onChange={(value) => {
                              setUserAccount("");
                              setUserBankOptions([]);
                              setUserAccountBrand("");
                              setError((prev) => ({ ...prev, userAccountType: "" }));
                              setUserAccountType(value);
                              handleUserAccountTypeChange(value.value, setUserAccountBrandOptions, setUserAccountBrand);
                            }}
                          />
                          {error?.userAccountType ? <span className="error d-block">{error.userAccountType}</span> : ""}
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-4">
                        <div className="form-group sky_bg_group">
                          <label htmlFor="inputEmail4" className="form-label">
                            {t("account-brand")} <sup className="req_txt">*</sup>
                          </label>
                          <DropDown
                            className={"me-2"}
                            id="dropdown"
                            value={userAccountBrand}
                            name="Account"
                            placeholder={t("choose-brand")}
                            options={userAccountBrandOptions}
                            onChange={(value) => {
                              setError((prev) => ({ ...prev, userAccountBrand: "" }));
                              setUserAccountBrand(value);
                              handleUserAccountBrandChange(value.value, userAccountType.value);
                            }}
                          />
                          {error?.userAccountBrand ? (
                            <span className="error d-block">{error.userAccountBrand}</span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-xl-4">
                        <div className="form-field form-group sky_bg_group">
                          <label htmlFor="inputPassword4" className="form-label">
                            {t("account-name-number")}
                            <sup className="req_txt">*</sup>
                          </label>
                          <DropDown
                            className={"me-2"}
                            id="dropdown"
                            value={userAccount}
                            name="Account"
                            placeholder={t("choose-account")}
                            options={userBankOptions}
                            onChange={(value) => {
                              setError((prev) => ({ ...prev, userAccount: "" }));
                              setUserAccount(value);
                            }}
                          />
                          {error?.userAccount ? <span className="error d-block">{error.userAccount}</span> : ""}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <InputFieldFormik
                            name={"amount"}
                            placeholder="Type here"
                            label={t("transfer-amount")}
                            type={"text"}
                            required={true}
                            min="0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-6 col-xl-4">
                        <div className="form-group sky_bg_group">
                          <label htmlFor="inputPassword4" className="form-label">
                            {t("destination-account-type")} <sup className="req_txt">*</sup>
                          </label>
                          <DropDown
                            className={"me-2"}
                            id="dropdown"
                            value={adminAccountType}
                            name="Account"
                            placeholder={t("choose-account-type")}
                            options={destinationAccountOptions}
                            onChange={(value) => {
                              setDestination("");
                              setDestinationBankOptions([]);
                              setAdminAccountBrand("");
                              setError((prev) => ({ ...prev, adminAccountType: "" }));
                              setAdminAccountType(value);
                              handleAdminAccountTypeChange(
                                value.value,
                                setAdminAccountBrandOptions,
                                setAdminAccountBrand
                              );
                            }}
                          />
                          {error?.adminAccountType ? (
                            <span className="error d-block">{error.adminAccountType}</span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-4">
                        <div className="form-group sky_bg_group">
                          <label htmlFor="inputEmail4" className="form-label">
                            {t("account-brand")}
                            <sup className="req_txt">*</sup>
                          </label>
                          <DropDown
                            className={"me-2"}
                            id="dropdown"
                            value={adminAccountBrand}
                            name="Account"
                            placeholder={t("choose-brand")}
                            options={adminAccountBrandOptions}
                            onChange={(value) => {
                              setError((prev) => ({ ...prev, adminAccountBrand: "" }));
                              setAdminAccountBrand(value);
                              handleAdminAccountBrandChange(value.value, adminAccountType?.value);
                            }}
                          />
                          {error?.adminAccountBrand ? (
                            <span className="error d-block">{error.adminAccountBrand}</span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-xl-4">
                        <div className="form-group sky_bg_group">
                          <label htmlFor="inputPassword4" className="form-label">
                            {t("transfer-destination")} <sup className="req_txt">*</sup>
                          </label>
                          <DropDown
                            className={"me-2"}
                            id="dropdown"
                            value={destination}
                            name="Account"
                            placeholder={t("choose-account")}
                            options={destinationBankOptions}
                            onChange={(value) => {
                              setError((prev) => ({ ...prev, destination: "" }));
                              setDestination(value);
                            }}
                          />
                          {error?.destination ? <span className="error d-block">{error.destination}</span> : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"col-12"}>
                    <div className="form-group">
                      <InputFieldFormik name={"note"} placeholder="Type here" label={t("note")} type={"text"} />
                    </div>
                  </div>

                  <div className="col-xl-6">
                    <div className="form-group sky_bg_group">
                      <label htmlFor="inputPassword4" className="form-label">
                        {t("bonus")}
                      </label>
                      <DropDown
                        className={"me-2"}
                        id="dropdown"
                        value={props.values.depositBonusType}
                        name="depositBonusType"
                        placeholder={t("choose-bonus")}
                        options={showBonusTypeOptions()}
                        onChange={(value) => {
                          props.setFieldValue("depositBonusType", value);
                        }}
                        isDisabled={
                          !isFirstDeposit && (userDetails?.depositBonusType == 1 || userDetails?.depositBonusType == 2)
                        }
                      />
                      {error?.depositBonusType ? <span className="error d-block">{error.depositBonusType}</span> : ""}
                    </div>
                  </div>

                  {destination ? (
                    <div className="col-12">
                      <div className="d-flex align-items-center bank_card text-dark">
                        <figure className="me-3 mb-0">
                          <img
                            src={
                              adminAccounts?.[adminAccountType.value]?.[adminAccountBrand.value]?.[destination.index]
                                ?.bankImage
                            }
                            alt="Bank"
                            className="w-100"
                            width="200"
                          />
                        </figure>
                        <h6 className="h5 text-capitalize">
                          <span className="d-block">
                            {
                              adminAccounts?.[adminAccountType.value]?.[adminAccountBrand.value]?.[destination.index]
                                ?.accountName
                            }
                          </span>
                          <span className="d-block">
                            {
                              adminAccounts?.[adminAccountType.value]?.[adminAccountBrand.value]?.[destination.index]
                                ?.accountNumber
                            }
                          </span>
                        </h6>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-12 text-end common_btn mt-md-4">
                    <Button className="btn btn-lg btn-primary" type="submit">
                      {t("deposit")}
                    </Button>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
      <Popup open={openModal} handleToggle={() => setOpenModal(!openModal)} message={t("success-msg")} />
    </div>
  );
};

export default DepositForm;
